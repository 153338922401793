import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../servicios/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  private lottieConfig:any;
  private mostrarSpinner:boolean = false;

  constructor(private spinnerService:SpinnerService){
    this.lottieConfig = {
      path: '../../../assets/cargando.json',
      renderer: 'svg',
      autoplay: true,
      loop: true,
      rendererSettings: {}
    };
  }

  ngOnInit(){
    this.spinnerService.estadoSpinner()
    .subscribe((estado:boolean) => {
      this.mostrarSpinner = estado;
    });
  }
}
