import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  textoFooter: string;

  constructor() {
    this.textoFooter = 'Derechos Reservados - QA/QC - MSW Tecnologías';
  }

  ngOnInit() {}
}
