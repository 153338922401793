import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dominio } from '../dominio';
import { INuevoUsuario, INuevoEstablecerContrasena, IOlvidoContrasena, IRestablecerContrasena, INuevoRestablecerContrasena } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(private http:HttpClient){}

  public obtenerUsuarios(token:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/user`, cabeceras);
  }

  public obtenerUsuario(idUsuario:string){
    return this.http.get(`${dominio}/user/${idUsuario}`);
  }

  public registrarUsuario(token:string, usuario:INuevoUsuario){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/user/adduser`, usuario, cabeceras);
  }

  public eliminarUsuario(token:string, idUsuario:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.delete(`${dominio}/user/${idUsuario}`, cabeceras);
  }

  public establecerContrasena(objetoEstablecerContrasena:INuevoEstablecerContrasena){
    return this.http.post(`${dominio}/user/assignpassword`, objetoEstablecerContrasena, { responseType: 'text' });
  }

  public olvidoContrasena(objetoOlvidoContrasena:IOlvidoContrasena){
    return this.http.post(`${dominio}/user/forgotpassword`, objetoOlvidoContrasena, { responseType: 'text' });
  }

  public restablecerContrasena(objetoRestablecerContrasena:INuevoRestablecerContrasena){
    return this.http.post(`${dominio}/user/resetpassword`, objetoRestablecerContrasena, { responseType: 'text' });
  }
}
