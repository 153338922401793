import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ILogin } from '../interfaces';
import { dominio } from '../dominio';


@Injectable({
  providedIn: 'root'
})
export class SesionService {
  private sessionSubject = new BehaviorSubject<boolean>(!!localStorage.getItem('token'));
  public loggedIn$ = this.sessionSubject.asObservable();

  constructor(private http:HttpClient, private router:Router) { }

  public login(data:ILogin){
    return this.http.post(`${dominio}/auth`, data).pipe(tap(() => this.sessionSubject.next(true)));
  }

  public logout(){
    this.sessionSubject.next(false);
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    this.router.navigate(['/']);
  }
}
