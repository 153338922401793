import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mensaje-modal',
  templateUrl: './mensaje-modal.component.html',
  styleUrls: ['./mensaje-modal.component.css']
})
export class MensajeModalComponent implements OnInit {

  @Input() private tituloMensaje:string;
  @Input() private contenidoMensaje:string;

  constructor(){
    this.tituloMensaje = '';
    this.contenidoMensaje = '';
  }

  ngOnInit(){}
}
