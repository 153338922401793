import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  private tokenUsuario:string;
  private roleUsuario:string;

  constructor(private router:Router){
    this.tokenUsuario = localStorage.getItem('token');
    this.roleUsuario = localStorage.getItem('role');
  }

  canActivate(){
    if(this.tokenUsuario !== null){
      if(this.roleUsuario === 'administrador'){
        this.router.navigate(['lotes']);
        return false;
      }
      else{
        this.router.navigate(['usuario','lotes']);
        return false;
      }
    }
    else {
      return true;
    }
  }
}
