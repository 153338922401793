import { Component, OnInit, Injectable } from '@angular/core';
import { EstadosService } from '../../../servicios/estados.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IEstado } from '../../../interfaces';

declare var $:any;

@Component({
  selector: 'app-estados',
  templateUrl: './estados.component.html',
  styleUrls: ['./estados.component.css']
})

@Injectable()
export class EstadosComponent implements OnInit {

  private token:string;
  private listaEstados:Array<IEstado>;
  private datosEditarEstado:IEstado;
  private tituloAlerta:string;
  private contenidoAlerta:string;
  private textoBotonAlerta:string;
  private idEstadoEliminar:string;

  constructor(private administracionServicio:EstadosService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.token = localStorage.getItem('token');
    this.listaEstados = [];
    this.datosEditarEstado = undefined;
    this.tituloAlerta = '';
    this.contenidoAlerta = '';
    this.textoBotonAlerta = 'Eliminar';
  }

  ngOnInit(){
    this.spinnerService.cargandoSpinner();
    this.obtenerEstadosAdministracion();
  }

  obtenerEstadosAdministracion(){
    this.administracionServicio.obtenerEstados(this.token)
    .subscribe((data:Array<IEstado>) => {
      this.listaEstados = data;
      this.spinnerService.cerrarSpinner();
    });
  }

  agregarEstadoAdministracion(){
    $('#agregarEstado').modal('show');
    this.datosEditarEstado = undefined;
    this.focusService.modalAbierto(true);
  }

  editarEstadoAdministracion(estado:IEstado){
    $('#agregarEstado').modal('show');
    this.datosEditarEstado = estado;
    this.focusService.modalAbierto(true);
  }

  eliminarEstadoAdministracion(idEstado:string){
    this.administracionServicio.eliminarEstado(this.token, idEstado)
    .subscribe(() => {
      this.eliminarEstadoLista(idEstado);
    },
    ((error:any) => {
      console.log(error);
    }));
  }

  obtenerNuevoEstado(estado:IEstado){
    this.listaEstados.push(estado);
  }

  obtenerEstadoEditado(estadoEditado:IEstado){
    const indiceEstadoEncontrado = this.listaEstados.findIndex(estado => estado.id === estadoEditado.id);
    this.listaEstados.splice(indiceEstadoEncontrado, 1, estadoEditado);
  }

  eliminarEstadoLista(idEstado:string){
    const indiceEstadoEncontrado = this.listaEstados.findIndex(estado => estado.id === idEstado);
    this.listaEstados.splice(indiceEstadoEncontrado, 1);
    $('#alertaModal').modal('hide');
  }

  mostrarAlertaEliminarEstado(idEstado:string){
    $('#alertaModal').modal('show');
    this.tituloAlerta = 'Eliminar Estado';
    this.contenidoAlerta = '¿Realmente desea eliminar el estado?';
    this.textoBotonAlerta = 'Eliminar';
    this.idEstadoEliminar = idEstado;
  }

  cambiarValorEstado(valor:any){
    this.datosEditarEstado = valor;
  }
}
