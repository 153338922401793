import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LotesService } from '../../../servicios/lotes.service';
import { BarrenoService } from '../../../servicios/barreno.service';

declare var $:any;

@Component({
  selector: 'app-subir-archivos-lote-modal',
  templateUrl: './subir-archivos-lote-modal.component.html',
  styleUrls: ['./subir-archivos-lote-modal.component.css']
})
export class SubirArchivosLoteModalComponent implements OnInit {

  @ViewChild('inputDocumento', { static: false }) campoDocumento;
  @ViewChild('spinnerBoton', { static: false }) spinnerBotonGuardar;
  @Input() public token:string;
  @Input() public idLote:any;
  @Input() public esLote:boolean;
  @Input() public archivosLote:Array<any>;
  private formularioArchivos:any;
  private nombreArchivo:string;
  private tituloAlerta:string;
  private contenidoAlerta:string;
  private idElementoEliminar:string;
  private coleccionArchivos:FormData;
  private rutaIcono:string;
  public deshabilitarBotonEnviar:boolean = false;

  constructor(
    private _formBuilder:FormBuilder,
    private _loteService:LotesService,
    private _barrenoService:BarrenoService
  ){
    this.formularioArchivos = this._formBuilder.group({
      documento: ['', [Validators.required]]
    });
    this.nombreArchivo = 'Sin archivo';
    this.archivosLote = [];
    this.tituloAlerta = 'Eliminar Archivo';
  }

  ngOnInit(): void {}

  public enviarArchivos(): void {
    const { nativeElement } = this.spinnerBotonGuardar;
    nativeElement.style.display = 'inline-block';
    this.deshabilitarBotonEnviar = true;
    if(this.esLote){
      this._loteService.agregarArchivosLote(this.token, this.idLote, this.coleccionArchivos)
      .subscribe((response:Array<any>) => {
        this.archivosLote = [...this.archivosLote, ...response];
        nativeElement.style.display = 'none';
        this.deshabilitarBotonEnviar = false;
      },
      (error:any) => {
        console.log(error);
        nativeElement.style.display = 'none';
        this.deshabilitarBotonEnviar = false;
      });
    }
    else {
      this._barrenoService.agregarArchivosBarreno(this.token, this.idLote, this.coleccionArchivos)
      .subscribe((response:Array<any>) => {
        this.archivosLote = [...this.archivosLote, ...response];
        nativeElement.style.display = 'none';
        this.deshabilitarBotonEnviar = false;
      },
      (error:any) => {
        console.log(error);
        nativeElement.style.display = 'none';
        this.deshabilitarBotonEnviar = false;
      });
    }
  }

  public descargarArchivo(nombreArchivo:string): void {
    if(this.esLote){
      this._loteService.descargarArchivoLote(this.token, this.idLote, nombreArchivo)
      .subscribe((response:any) => {
        const elementoEnlace = document.createElement('a');
        elementoEnlace.href = window.URL.createObjectURL(response);
        elementoEnlace.setAttribute('download', nombreArchivo);
        document.body.appendChild(elementoEnlace);
        elementoEnlace.click();
      });
    }
    else {
      this._barrenoService.descargarArchivoBarreno(this.token, this.idLote, nombreArchivo)
      .subscribe((response:any) => {
        const elementoEnlace = document.createElement('a');
        elementoEnlace.href = window.URL.createObjectURL(response);
        elementoEnlace.setAttribute('download', nombreArchivo);
        document.body.appendChild(elementoEnlace);
        elementoEnlace.click();
      });
    }
  }

  public eliminarArchivo(nombreArchivo:string): void {
    if(this.esLote){
      this._loteService.eliminarArchivoLote(this.token, this.idLote, nombreArchivo)
      .subscribe(() => {
        const indice = this.archivosLote.findIndex(archivo => archivo.fileName.toLowerCase() === nombreArchivo.toLocaleLowerCase());
        this.archivosLote.splice(indice, 1);
      });
    }
    else {
      this._barrenoService.eliminarArchivoBarreno(this.token, this.idLote, nombreArchivo)
      .subscribe(() => {
        const indice = this.archivosLote.findIndex(archivo => archivo.fileName.toLowerCase() === nombreArchivo.toLocaleLowerCase());
        this.archivosLote.splice(indice, 1);
      });
    }
  }

  public subirArchivo(): void {
    const { nativeElement } = this.campoDocumento;
    nativeElement.click();
  }

  public obtenerArchivo(event): void {
    this.coleccionArchivos = new FormData();
    let listaNombresArchivos = [];

    if(event.target.files && event.target.files.length){
      const archivos = [...event.target.files];
      archivos.forEach((elemento:File) => {
        listaNombresArchivos.push(elemento.name);
        this.coleccionArchivos.append('files', elemento);
      });
      this.nombreArchivo = listaNombresArchivos.join(', ');
    }
  }

  public mostrarIconoArchivo(nombreArchivo:string): string {
    const extension = nombreArchivo.split('.').pop().toLowerCase();
    if(extension === 'xls' || extension === 'xlsx')
      this.rutaIcono = 'assets/iconos/excel.svg';
    else if(extension === 'doc' || extension === 'docx')
      this.rutaIcono = 'assets/iconos/docx.svg';
    else if(extension === 'zip' || extension === 'rar')
      this.rutaIcono = 'assets/iconos/zip.svg';
    else if(extension === 'pdf')
      this.rutaIcono = 'assets/iconos/pdf.svg';
    else if(extension === 'jpg')
      this.rutaIcono = 'assets/iconos/jpg.svg';
    else if(extension === 'png')
      this.rutaIcono = 'assets/iconos/png.svg';
    else if(extension === 'txt')
      this.rutaIcono = 'assets/iconos/txt.svg';
    else if(extension === 'bmp')
      this.rutaIcono = 'assets/iconos/bmp.svg';
    else
      this.rutaIcono = 'assets/iconos/default.svg';

    return this.rutaIcono;
  }

  public cerrarModal(): void {
    const { nativeElement } = this.campoDocumento;
    nativeElement.value = "";
  }
}
