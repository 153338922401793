import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ProyectosService } from '../../../servicios/proyectos.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IRegion, IProyecto } from 'src/app/interfaces';

declare var $:any;

@Component({
  selector: 'app-agregar-proyecto-modal',
  templateUrl: './agregar-proyecto-modal.component.html',
  styleUrls: ['./agregar-proyecto-modal.component.css']
})
export class AgregarProyectoModalComponent implements OnInit {

  @ViewChild('inputProyecto', { static: true }) inputProyecto:ElementRef;
  @Input() private token:string;
  @Input() private listaRegiones:Array<IRegion>;
  @Input() private datosEditarProyecto:IProyecto;
  @Output() private enviarProyectoAgregado:EventEmitter<IProyecto>;
  @Output() private enviarProyectoEditado:EventEmitter<IProyecto>;
  @Output() private cambiarValorProyectoEditado:EventEmitter<any>;
  private formAgregarProyecto:FormGroup;
  private mostrarSelectRegiones:boolean;
  private idProyecto:string;

  constructor(private formBuilder:FormBuilder, private proyectoService:ProyectosService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.idProyecto = '';
    this.listaRegiones = [];
    this.mostrarSelectRegiones = true;
    this.enviarProyectoAgregado = new EventEmitter<IProyecto>();
    this.enviarProyectoEditado = new EventEmitter<IProyecto>();
    this.cambiarValorProyectoEditado = new EventEmitter<any>();
    this.formAgregarProyecto = formBuilder.group({
      region: ['', Validators.compose([Validators.required])],
      proyecto: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
    });

    this.focusService.obtenerEstado()
    .subscribe((estado:any) => {
      if(estado.abierto){
        setTimeout(() => {
          this.inputProyecto.nativeElement.focus();
        }, 500);
      }
    });
  }

  ngOnInit(){}

  ngOnChanges(){
    if(this.datosEditarProyecto !== undefined){
      this.establecerProyectoEditar();
      this.mostrarSelectRegiones = false;
      this.formAgregarProyecto.controls['region'].clearValidators();        // Se limpia la validación del campo 'region' para que no se requerido
      this.formAgregarProyecto.controls['region'].updateValueAndValidity(); // cuando se edite el proyecto (porque no aparece la opción de agregar región)
    }
  }

  registrarProyecto(){
    this.spinnerService.cargandoSpinner();
    this.proyectoService.registrarProyecto(this.token, this.formAgregarProyecto.value.region, { proyecto: this.formAgregarProyecto.value.proyecto })
    .subscribe((data:IProyecto) => {
      this.enviarProyectoAgregado.emit(data);
      this.limpiarFormulario();
      $('#agregarProyecto').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  editarProyecto(){
    this.spinnerService.cargandoSpinner();
    this.proyectoService.actualizarProyecto(this.token, { id: this.idProyecto, proyecto: this.formAgregarProyecto.value.proyecto })
    .subscribe((data:IProyecto) => {
      this.enviarProyectoEditado.emit(data);
      this.limpiarFormulario();
      $('#agregarProyecto').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    ((error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    }));
  }

  accionRegion(){
    if(this.datosEditarProyecto !== undefined){
      this.editarProyecto();
    }
    else {
      this.registrarProyecto();
    }
  }

  limpiarFormulario(){
    this.idProyecto = '';
    this.formAgregarProyecto.controls['region'].setValue('');
    this.formAgregarProyecto.controls['proyecto'].setValue('');
    this.mostrarSelectRegiones = true; // Se cambia la bandera a 'true' para que se muestre el select de regiones por si se necesita registrar un proyecto (en editar no se necesita el select)
    this.formAgregarProyecto.controls['region'].setValidators([Validators.required]); // Una vez que se cierra la modal, se vuelve a agregar la validación de requerido a el campo
    this.formAgregarProyecto.controls['region'].updateValueAndValidity();
    this.cambiarValorProyectoEditado.emit(undefined);             // 'region' por si se necesita registrar un proyecto (se necesita seleccionar una región).
  }

  establecerProyectoEditar(){
    this.idProyecto = this.datosEditarProyecto.id;
    this.formAgregarProyecto.controls['proyecto'].setValue(this.datosEditarProyecto.proyecto);
    this.formAgregarProyecto.controls['region'].setValue(this.datosEditarProyecto.region.id);
  }
}
