import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { dominio } from '../dominio';
import { INuevoBarreno, IBarreno } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class BarrenoService {

  constructor(private http:HttpClient){ }

  public obtenerBarrenos(token:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/barreno`, cabeceras);
  }

  public obtenerDetalleBarreno(token:string, idBarreno:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/barreno/getdetallebarreno/${idBarreno}`, cabeceras);
  }

  public registrarBarreno(token:string, idLote:number, barreno:INuevoBarreno){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/barreno/${idLote}`, barreno, cabeceras);
  }

  public agregarArchivosBarreno(token:string, idBarreno:string, archivos:FormData){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/Barreno/UploadFilesToBarreno/${idBarreno}`, archivos, cabeceras);
  }

  public obtenerArchivosBarreno(token:string, idBarreno:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/Barreno/GetFilesBarreno/${idBarreno}`, cabeceras);
  }

  public descargarArchivoBarreno(token:string, idBarreno:string, nombreArchivo:string){
    return this.http.get(`${dominio}/Barreno/DownloadFileBarreno/${idBarreno}/${nombreArchivo}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}` 
      })
    });
  }

  public eliminarArchivoBarreno(token:string, idBarreno:string, nombreArchivo:string){
    return this.http.delete(`${dominio}/Barreno/DeleteFileBarreno/${idBarreno}/${nombreArchivo}`, {
      responseType: 'text',
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    });
  }

  public importarMuestras(token:string, importacion:any, idBarreno:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/barreno/importmuestras/${idBarreno}`, importacion, cabeceras);
  }

  public actualizarBarreno(token:string, barreno:INuevoBarreno){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.put(`${dominio}/barreno/${barreno.idBarreno}`, barreno, cabeceras);
  }

  public eliminarBarreno(token:string, idBarreno:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.delete(`${dominio}/barreno/${idBarreno}`, cabeceras);
  }

  public exportarMuestrasBarreno(token: string, idBarreno: string) {
    return this.http.get(`${dominio}/barreno/ExportMuestras/${idBarreno}`, {
      responseType: 'arraybuffer',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    });
  }
}
