import { Component, OnInit } from '@angular/core';
import { EstadosService } from '../../../servicios/estados.service';
import { RegionesService } from '../../../servicios/regiones.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IRegion, IEstado } from 'src/app/interfaces';

declare var $:any;

@Component({
  selector: 'app-regiones',
  templateUrl: './regiones.component.html',
  styleUrls: ['./regiones.component.css']
})
export class RegionesComponent implements OnInit {

  private token:string;
  private listaEstados:Array<IEstado>;
  private listaRegiones:Array<IRegion>;
  private tituloAlerta:string;
  private contenidoAlerta:string;
  private textoBotonAlerta:string;
  private idRegionEliminar:string;
  private datosEditarRegion:IRegion;

  constructor(private estadosService:EstadosService, private regionesService:RegionesService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.token = localStorage.getItem('token');
    this.listaEstados = [];
    this.listaRegiones = [];
    this.tituloAlerta = '';
    this.contenidoAlerta = '';
    this.textoBotonAlerta = 'Eliminar';
    this.idRegionEliminar = '';
    this.datosEditarRegion = undefined;
  }

  ngOnInit(){
    this.spinnerService.cargandoSpinner();
    this.obtenerRegionesAdministracion();
  }

  obtenerRegionesAdministracion(){
    this.regionesService.obtenerRegiones(this.token)
    .subscribe((data:Array<IRegion>) => {
      this.listaRegiones = data;
      this.obtenerEstados();
      this.spinnerService.cerrarSpinner();
    });
  }

  agregarRegionAdministracion(){
    $('#agregarRegion').modal('show');
    this.datosEditarRegion = undefined;
    this.focusService.modalAbierto(true);
  }

  editarRegionAdministracion(region:IRegion){
    $('#agregarRegion').modal('show');
    this.datosEditarRegion = region;
    this.focusService.modalAbierto(true);
  }

  eliminarRegionAdministracion(idRegion:string){
    this.regionesService.eliminarRegion(this.token, idRegion)
    .subscribe(() => {
      this.eliminarRegionLista(idRegion);
    },
    (error:any) => {
      console.log(error);
    });
  }

  obtenerNuevaRegion(region:IRegion){
    this.listaRegiones.push(region);
  }

  obtenerEstados(){
    this.estadosService.obtenerEstados(this.token)
    .subscribe((data:Array<IEstado>) => {
      this.listaEstados = data;
    });
  }

  obtenerRegionEditada(regionEditada:IRegion){
    const indiceRegionEncontrada = this.listaRegiones.findIndex(region => region.id === regionEditada.id);
    this.listaRegiones.splice(indiceRegionEncontrada, 1, regionEditada);
  }

  eliminarRegionLista(idRegion:string){
    const indiceRegionEncontrada = this.listaRegiones.findIndex(region => region.id === idRegion);
    this.listaRegiones.splice(indiceRegionEncontrada, 1);
    $('#alertaModal').modal('hide');
  }

  mostrarAlertaEliminarRegion(idRegion){
    $('#alertaModal').modal('show');
    this.tituloAlerta = 'Eliminar Región';
    this.contenidoAlerta = '¿Realmente desea eliminar la región?';
    this.textoBotonAlerta = 'Eliminar';
    this.idRegionEliminar = idRegion;
  }

  cambiarValorRegion(valor:any){
    this.datosEditarRegion = valor;
  }
}
