import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from '../../servicios/sesion.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  private role:string;
  private esAdministrador:boolean;

  constructor(private router:Router, private sesionService:SesionService){
    this.role = '';
    this.esAdministrador = localStorage.getItem('role') === 'usuario' ? false : true;
  }

  ngOnInit(){
    this.role = localStorage.getItem('role');
  }

  cerrarSesion(){
    this.sesionService.logout();
  }
}
