import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { LotesService } from '../../../servicios/lotes.service';
import { BarrenoService } from '../../../servicios/barreno.service';
import { MuestrasService } from '../../../servicios/muestras.service';
import { ProyectosService } from '../../../servicios/proyectos.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IBarreno, IEquipo, IProyecto, IMuestra } from 'src/app/interfaces';

declare var $:any;
@Component({
  selector: 'app-muestras',
  templateUrl: './muestras.component.html',
  styleUrls: ['./muestras.component.css']
})
export class MuestrasComponent implements OnInit {

  @ViewChild('seleccion', { static: true }) seleccion:ElementRef;
  public token:string;
  private idBarreno:string;
  private nombreLote:string;
  private fechaRegistroLote:string;
  private analisisQuimicoLote:boolean;
  private envioLote:boolean;
  private listaMuestras:Array<IMuestra>;
  private listaBarrenos:Array<any>;
  private listaTipoEquipos:Array<IEquipo>;
  private listaProyectos:Array<IProyecto>;
  private listaTipoMuestras:Array<any>;
  private listaTipoMuestrasManual:Array<any>;
  private tituloAlerta:string;
  private contenidoAlerta:string;
  private textoBotonAlerta:string;
  private idMuestraEliminar:string;
  private muestraAnalisis:IMuestra;
  private muestraManual:boolean;
  private nombreBarreno:string;
  private muestreadoPor:string;
  private fechaRegistroBarreno:string;
  private azimut:string;
  private inclinacion:number;
  private este:number;
  private norte:number;
  private elevacion:number;
  private pt:number;
  private nombreMuestraComentario:string;
  private contenidoMuestraComentario:string;
  private mostrarVerMas:boolean;
  private esAdministrador:boolean;
  public listaArchivosBarreno:Array<any>;

  private fileUrl;

  constructor(private route: ActivatedRoute,
    private lotesService: LotesService,
    private _barrenoService: BarrenoService,
    private proyectosService: ProyectosService,
    private muestrasService: MuestrasService,
    private sanitizer: DomSanitizer,
    private location: Location, private focusService: FocusService, private spinnerService: SpinnerService) {
    this.token = localStorage.getItem('token');
    this.esAdministrador = localStorage.getItem('role') === 'usuario' ? false : true;
    this.idBarreno = '';
    this.nombreLote = '';
    this.fechaRegistroLote = '';
    this.analisisQuimicoLote = false;
    this.envioLote = false;
    this.listaMuestras = [];
    this.listaBarrenos = [];
    this.listaTipoEquipos = [];
    this.listaProyectos = [];
    this.listaTipoMuestras = [];
    this.listaTipoMuestrasManual = [];
    this.listaArchivosBarreno = [];
    this.tituloAlerta = '';
    this.contenidoAlerta = '';
    this.textoBotonAlerta = 'Eliminar';
    this.muestraManual = false;
    this.nombreBarreno = '';
    this.muestreadoPor = '';
    this.fechaRegistroBarreno = '';
    this.azimut = '';
    this.inclinacion = 0;
    this.este = 0;
    this.norte = 0;
    this.elevacion = 0;
    this.pt = 0;
    this.nombreMuestraComentario = '';
    this.contenidoMuestraComentario = '';
  }

  ngOnInit(){
    this.spinnerService.cargandoSpinner();
    this.obtenerParametrosRuta();
    this.obtenerListaBarrenos();
  }

  agregarMuestraAdministracion(){
    $('#agregarMuestra').modal('show');
    this.muestraManual = false;
    this.focusService.modalAbierto(true);
  }

  eliminarMuestraAdministracion(idMuestra:string){
    this.muestrasService.eliminarMuestra(this.token, idMuestra)
    .subscribe(() => {
      this.muestrasService.obtenerMuestras(this.token, this.idBarreno)
      .subscribe((muestras:Array<IMuestra>) => {
        this.listaMuestras = muestras;
        $('#alertaModal').modal('hide');
      });
    },
    (error:any) => {
      console.log(error);
    });
  }

  agregarMuestraManual(){
    $('#agregarMuestra').modal('show');
    this.muestraManual = true;
    this.focusService.modalAbierto(true);
  }

  agregarMuestraAutomatica(){
    this.muestrasService.registrarMuestraAutomatica(this.token, this.idBarreno)
    .subscribe(() => {
      window.location.reload();
      this.spinnerService.cargandoSpinner();
    });
  }

  importarMuestras(){
    this.seleccion.nativeElement.click();
  }
  // Jr
  // Se exportan las muestras del barreno a excel
  exportarMuestras() {
    this._barrenoService
      .exportarMuestrasBarreno(this.token, this.idBarreno)
      .subscribe((dataFile: any) => {
        const blob = new Blob([dataFile], { type: 'application/ms-excel' });
        const url = window.URL.createObjectURL(blob);
        const responseFile = document.createElement('a');
        document.body.appendChild(responseFile);
        responseFile.setAttribute('style', 'display: none');
        responseFile.href = url;
        responseFile.download = `Barreno-${this.nombreBarreno}.xlsx`;
        responseFile.click();
        window.URL.revokeObjectURL(url);
        responseFile.remove();
      });
  }

  obtenerListaProyectos(){
    this.proyectosService.obtenerProyectos(this.token)
    .subscribe((data:Array<IProyecto>) => {
      this.listaProyectos = data;
    });
  }

  obtenerListaBarrenos(){
    this._barrenoService.obtenerBarrenos(this.token)
    .subscribe((data:any) => {
      this.listaBarrenos = data.barrenos;
      this.listaTipoEquipos = data.tiposEquipo;
      this.obtenerListaProyectos();
      this.obtenerListaTipoMuestras();
    });
  }

  agregarAnalisisQuimico(muestra:IMuestra){
    $('#editarMuestra').modal('show');
    this.muestraAnalisis = muestra;
    this.focusService.modalAbierto(true);
  }

  agregarBarreno(){
    $('#agregarBarreno').modal('show');
    this.focusService.modalAbierto(true);
  }

  barrenoAgregado(barreno:IBarreno){
    this.listaBarrenos.push(barreno);
  }

  obtenerNuevaMuestra(muestra:any){
    this.obtenerDetalleBarreno(this.idBarreno);
  }

  recibirAnalisisQuimico(analisis:IMuestra){
    this.remplazarMuestra(analisis);
  }

  mostrarAlertaEliminarMuestra(idMuestra:string){
    $('#alertaModal').modal('show');
    this.tituloAlerta = 'Eliminar Muestra';
    this.contenidoAlerta = '¿Realmente desea eliminar la muestra?';
    this.textoBotonAlerta = 'Eliminar';
    this.idMuestraEliminar = idMuestra;
  }

  regresar(){
    this.location.back();
  }

  public obtenerParametrosRuta(){
    this.route.params
    .subscribe((data:any) => {
      this.obtenerDetalleBarreno(data.id);
      this.idBarreno = data.id;
      this.obtenerArchivosBarreno(data.id);
    });
  }

  obtenerListaTipoMuestras(){
    this.lotesService.obtenerLotes(this.token)
    .subscribe((data:any) => {
      this.listaTipoMuestras = data.tiposMuestra;
      this.listaTipoMuestrasManual = data.tiposMuestra.filter(tipo => tipo.clave !== 'MAQ');
    });
  }

  obtenerDetalleBarreno(idBarreno:string){
    this._barrenoService.obtenerDetalleBarreno(this.token, idBarreno)
    .subscribe((data:any) => {
      this.nombreBarreno = data.idBarreno;
      this.muestreadoPor = data.muestreadoPor;
      this.fechaRegistroBarreno = data.fecha;
      this.azimut = data.azimut;
      this.inclinacion = data.inclinacion;
      this.este = data.este;
      this.norte = data.norte;
      this.elevacion = data.elevacion;
      this.pt = data.pt;
      this.listaMuestras = data.muestras;
      this.spinnerService.cerrarSpinner();
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  generarIdMaq(){
    this.muestrasService.generarIdMuestra(this.token, this.idBarreno)
    .subscribe(() => {
      window.location.reload();
      this.spinnerService.cargandoSpinner();
    });
  }

  remplazarMuestra(analisis:IMuestra){
    const indiceMuestraEncontrada = this.listaMuestras.findIndex(muestra => muestra.id === analisis.id);
    this.listaMuestras.splice(indiceMuestraEncontrada, 1, analisis);
  }

  eliminarMuestraLista(idMuestra:string){
    const indiceMuestraEncontrado = this.listaMuestras.findIndex(muestra => muestra.id === idMuestra)
    this.listaMuestras.splice(indiceMuestraEncontrado, 1);
    $('#alertaModal').modal('hide');
  }

  mostrarTipoMuestra = (id:string) => {
    const resultado = this.listaTipoMuestras.find(tipo => tipo.id === id);
    if(resultado !== undefined){
      return resultado.tipoMuestra;
    }
    else{
      return "";
    }
  }

  cambiarValorAnalisisQuimico(valor:any){
    this.muestraAnalisis = valor;
  }

  obtenerArchivoImportacion(archivo:File){
    this.spinnerService.cargandoSpinner();
    const arregloSplitPunto = archivo[0].name.split('.');
    const extensionArchivo = (arregloSplitPunto[arregloSplitPunto.length - 1]).toLowerCase();
    if(extensionArchivo !== 'xlsx'){
      this.textoBotonAlerta = '';
      this.spinnerService.cerrarSpinner();
      $('#alertaModal').modal('show');
      this.tituloAlerta = 'Error al importar excel';
      this.contenidoAlerta = 'El archivo debe tener la extensión .xlsx';
    }
    else{
      const formulario = new FormData();
      formulario.append('Archivo', archivo[0]);
      this._barrenoService.importarMuestras(this.token, formulario, this.idBarreno)
      .subscribe((data:any) => {
        this.listaMuestras = data.muestras;
        this.spinnerService.cerrarSpinner();
      },
      (error:any) => {
        this.spinnerService.cerrarSpinner();
        console.log(error);
      });
    }
  }

  public adjuntarArchivos(): void {
    $('#modalSubirArchicoLote').modal('show');
  }

  public obtenerArchivosBarreno(idBarreno:string): void {
    this._barrenoService.obtenerArchivosBarreno(this.token, idBarreno)
    .subscribe((response:any) => {
      console.log(response);
      this.listaArchivosBarreno = response;
    });
  }

  verComentario(muestra:IMuestra){
    $('#verComentarioModal').modal('show');
    this.nombreMuestraComentario = muestra.idMuestra;
    this.contenidoMuestraComentario = muestra.comentarios;
  }

  mostrarVerMasComentario(muestra:IMuestra){
    if (muestra !== undefined) {
      if (muestra.comentarios === null)
        return false;
      else if(muestra.comentarios.length > 15)
        return true;
      else
        return false;
    }
    else {
      return false;
    }
  }
}
