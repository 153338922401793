import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MuestrasService } from '../../../servicios/muestras.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IMuestra } from 'src/app/interfaces';

declare var $:any;

@Component({
  selector: 'app-editar-muestra-modal',
  templateUrl: './editar-muestra-modal.component.html',
  styleUrls: ['./editar-muestra-modal.component.css']
})
export class EditarMuestraModalComponent implements OnInit {

  @ViewChild('inputFet', { static: true }) inputFet:ElementRef;
  @Input() private token:string;
  @Input() private muestra:IMuestra;
  @Output() private enviarAnalisisQuimico:EventEmitter<any>;
  @Output() private cambiarValorAnalisisQuimico:EventEmitter<any>;
  private formEditarMuestra:FormGroup;

  constructor(private formBuilder:FormBuilder, private muestrasService:MuestrasService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.formEditarMuestra = this.formBuilder.group({
      fet: ['0', Validators.compose([])],
      fem: ['0', Validators.compose([])],
      comentario: ['', Validators.compose([])]
    });

    this.enviarAnalisisQuimico = new EventEmitter<any>();
    this.cambiarValorAnalisisQuimico = new EventEmitter<any>();

    this.focusService.obtenerEstado()
    .subscribe((estado:any) => {
      if(estado.abierto){
        setTimeout(() => {
          this.inputFet.nativeElement.focus();
        }, 500);
      }
    });
   }

  ngOnInit(){}

  ngOnChanges(){
    if(this.muestra !== undefined){
      this.establecerAnalisisQuimico();
    }
   }

  editarMuestra(){
    this.spinnerService.cargandoSpinner();
    this.muestrasService.editarMuestra(this.token, this.muestra.id, this.armarMuestra())
    .subscribe((data:IMuestra) => {
      this.enviarAnalisisQuimico.emit(data);
      this.limpiarFormulario();
      $('#editarMuestra').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  armarMuestra(){
    return {
      fet: parseFloat(this.formEditarMuestra.value.fet),
      fem: parseFloat(this.formEditarMuestra.value.fem),
      comentario: this.formEditarMuestra.value.comentario,
    };
  }

  establecerAnalisisQuimico(){
    this.formEditarMuestra.controls['fet'].setValue(this.muestra.fet);
    this.formEditarMuestra.controls['fem'].setValue(this.muestra.fem);
    this.formEditarMuestra.controls['comentario'].setValue(this.muestra.comentarios);
  }

  limpiarFormulario(){
    this.formEditarMuestra.controls['fet'].setValue('');
    this.formEditarMuestra.controls['fem'].setValue('');
    this.formEditarMuestra.controls['comentario'].setValue('');
    this.cambiarValorAnalisisQuimico.emit(undefined);
  }
}
