import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CompositosService } from '../../../servicios/compositos.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IComposito } from 'src/app/interfaces';

declare var $:any;

@Component({
  selector: 'app-agregar-composito-modal',
  templateUrl: './agregar-composito-modal.component.html',
  styleUrls: ['./agregar-composito-modal.component.css']
})
export class AgregarCompositoModalComponent implements OnInit {

  @ViewChild('textareaMuestras', { static: true }) textareaMuestras:ElementRef;
  @Input() private token:string;
  @Input() private idBarreno:string;
  @Input() private datosEditarComposito:IComposito;
  @Output() private compositoRegistrado:EventEmitter<IComposito>;
  @Output() private compositoEditado:EventEmitter<IComposito>;
  private formAgregarComposito:FormGroup;
  private idComposito:string;

  constructor(private formBuilder:FormBuilder, private compositoService:CompositosService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.formAgregarComposito = this.formBuilder.group({
      muestras: ['', Validators.compose([Validators.required])],
      fet: ['0', Validators.compose([Validators.required])],
      fem: ['0', Validators.compose([Validators.required])],
      ind: ['0', Validators.compose([Validators.required])],
      caO: ['0', Validators.compose([Validators.required])],
      mgO: ['0', Validators.compose([Validators.required])],
      al2O3: ['0', Validators.compose([Validators.required])],
      s: ['0', Validators.compose([Validators.required])],
      siO2: ['0', Validators.compose([Validators.required])],
    });

    this.idComposito = '';
    this.compositoRegistrado = new EventEmitter<IComposito>();
    this.compositoEditado = new EventEmitter<IComposito>();
    this.focusService.obtenerEstado()
    .subscribe((valor:any) => {
      setTimeout(() => {
        this.textareaMuestras.nativeElement.focus();
      }, 500);
    });
  }

  ngOnInit(){}

  ngOnChanges(){
    if(this.datosEditarComposito !== undefined){
      this.establecerCompositoEditar();
    }
  }

  accionComposito(){
    if(this.datosEditarComposito !== undefined){
      this.editarComposito();
    }
    else {
      this.registrarComposito();
    }
  }

  registrarComposito(){
    this.spinnerService.cargandoSpinner();
    this.compositoService.registrarComposito(this.token, this.armarRegistroComposito(), this.idBarreno)
    .subscribe((composito:IComposito) => {
      this.compositoRegistrado.emit(composito);
      $('#agregarComposito').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  editarComposito(){
    this.spinnerService.cargandoSpinner();
    this.compositoService.editarComposito(this.token, this.armarRegistroComposito(), this.idComposito)
    .subscribe((composito:IComposito) => {
      this.compositoEditado.emit(composito);
      this.limpiarFormulario();
      $('#agregarComposito').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  armarRegistroComposito(){
    return {
      s: Number(this.formAgregarComposito.value.s),
      fet: Number(this.formAgregarComposito.value.fet),
      fem: Number(this.formAgregarComposito.value.fem),
      ind: Number(this.formAgregarComposito.value.ind),
      caO: Number(this.formAgregarComposito.value.caO),
      mgO: Number(this.formAgregarComposito.value.mgO),
      siO2: Number(this.formAgregarComposito.value.siO2),
      al2O3: Number(this.formAgregarComposito.value.al2O3),
      muestras: this.formAgregarComposito.value.muestras,
    };
  }

  limpiarFormulario(){
    this.formAgregarComposito.controls['s'].setValue('0');
    this.formAgregarComposito.controls['fet'].setValue('0');
    this.formAgregarComposito.controls['fem'].setValue('0');
    this.formAgregarComposito.controls['ind'].setValue('0');
    this.formAgregarComposito.controls['caO'].setValue('0');
    this.formAgregarComposito.controls['mgO'].setValue('0');
    this.formAgregarComposito.controls['siO2'].setValue('0');
    this.formAgregarComposito.controls['al2O3'].setValue('0');
    this.formAgregarComposito.controls['muestras'].setValue('');
  }

  establecerCompositoEditar(){
    this.idComposito = this.datosEditarComposito.id;
    this.formAgregarComposito.controls['muestras'].setValue(this.datosEditarComposito.muestras);
    this.formAgregarComposito.controls['fet'].setValue(this.datosEditarComposito.fet);
    this.formAgregarComposito.controls['fem'].setValue(this.datosEditarComposito.fem);
    this.formAgregarComposito.controls['ind'].setValue(this.datosEditarComposito.ind);
    this.formAgregarComposito.controls['caO'].setValue(this.datosEditarComposito.caO);
    this.formAgregarComposito.controls['mgO'].setValue(this.datosEditarComposito.mgO);
    this.formAgregarComposito.controls['al2O3'].setValue(this.datosEditarComposito.al2O3);
    this.formAgregarComposito.controls['s'].setValue(this.datosEditarComposito.s);
    this.formAgregarComposito.controls['siO2'].setValue(this.datosEditarComposito.siO2);
  }
}
