import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-muestra',
  templateUrl: './muestra.component.html',
  styleUrls: ['./muestra.component.css']
})
export class MuestraComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
