import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dominio } from '../dominio';
import { INuevoProyecto, IEditarProyecto } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProyectosService {

  constructor(private http:HttpClient){ }

  public obtenerProyectos(token:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/proyecto`, cabeceras);
  }

  public registrarProyecto(token:string, idRegion:string, proyecto:INuevoProyecto){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/proyecto/${idRegion}`, proyecto, cabeceras);
  }

  public actualizarProyecto(token:string, proyecto:IEditarProyecto){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.put(`${dominio}/proyecto/${proyecto.id}`, proyecto, cabeceras);
  }

  public eliminarProyecto(token:string, idProyecto:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.delete(`${dominio}/proyecto/${idProyecto}`, cabeceras);
  }
}
