import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-alerta-modal',
  templateUrl: './alerta-modal.component.html',
  styleUrls: ['./alerta-modal.component.css']
})
export class AlertaModalComponent implements OnInit {

  @Input() private tituloAlerta:string;
  @Input() private contenidoAlerta:string;
  @Input() private textoBotonAlerta:string;
  @Input() private idElementoEliminar:string;
  @Output() private accionAlerta:EventEmitter<string>;
  private textoBotonSecundario:string;

  constructor(){
    this.tituloAlerta = '';
    this.contenidoAlerta = '';
    this.textoBotonAlerta = 'Eliminar';
    this.textoBotonSecundario = 'Cerrar';
    this.accionAlerta = new EventEmitter<string>();
  }

  ngOnInit(){}

  ejecutarAccionAlerta(){
    this.accionAlerta.emit(this.idElementoEliminar);
  }
}
