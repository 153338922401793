import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MuestrasService } from '../../../servicios/muestras.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IBarreno, ITipoMuestra, IMuestra } from 'src/app/interfaces';

declare var $:any;

@Component({
  selector: 'app-agregar-muestra-modal',
  templateUrl: './agregar-muestra-modal.component.html',
  styleUrls: ['./agregar-muestra-modal.component.css']
})
export class AgregarMuestraModalComponent implements OnInit {

  @ViewChild('inputDe', { static: true }) inputDe:ElementRef;
  @ViewChild('inputOrden', { static: true }) inputOrden:ElementRef;
  @Input() private token:string;
  @Input() private idBarreno:string;
  @Input() private listaBarrenos:Array<IBarreno>;
  @Input() private listaMuestras:Array<any>;
  @Input() private listaTipoMuestras:Array<ITipoMuestra>;
  @Input() private listaTipoMuestrasManual:Array<ITipoMuestra>;
  @Input() private muestraManual:boolean;
  @Output() private enviarMuestraAgregada:EventEmitter<any>;
  private formAgregarMuestra:FormGroup;
  private expresionNumeroDecimal:any;
  private mostrarOpcionesMuestra:boolean;

  constructor(private formBuilder:FormBuilder, private muestraService:MuestrasService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.listaTipoMuestras = [];
    this.listaTipoMuestrasManual = [];
    this.listaBarrenos = [];
    this.listaMuestras = [];
    this.expresionNumeroDecimal = /^(\d|-)?(\d|,)*\.?\d*$/;
    this.enviarMuestraAgregada = new EventEmitter<any>();
    this.mostrarOpcionesMuestra = false;

    this.formAgregarMuestra = this.formBuilder.group({
      barrenoEntityId: ['', Validators.compose([])],
      tipoMuestraEntityId: [''],
      muestra: [''],
      numOrden: ['0',  Validators.compose([])],
      de: ['0', Validators.compose([Validators.pattern(this.expresionNumeroDecimal)])],
      a: ['0', Validators.compose([Validators.pattern(this.expresionNumeroDecimal)])],
      numeroCaja: ['', Validators.compose([])],
      comentarios: ['', Validators.compose([Validators.maxLength(300)])],
    });

    this.focusService.obtenerEstado()
    .subscribe((estado:any) => {
      if(estado.abierto){
        setTimeout(() => {
          this.inputOrden.nativeElement.focus();
          this.inputDe.nativeElement.focus();
        }, 500);
      }
    });
  }

  ngOnInit(){}

  accionMuestra(){
    this.agregarMuestra();
  }

  agregarMuestra(){
    if(!this.muestraManual){
      this.spinnerService.cargandoSpinner();
      this.muestraService.registrarMuestra(this.token, this.idBarreno, this.armarMuestra())
      .subscribe((data:any) => {
        this.enviarMuestraAgregada.emit(data);
        this.limpiarFormulario();
        $('#agregarMuestra').modal('hide');
        this.spinnerService.cerrarSpinner();
      },
      (error:any) => {
        console.log(error);
        this.spinnerService.cerrarSpinner();
      });
    }
    else {
      this.spinnerService.cargandoSpinner();
      this.muestraService.registrarMuestraManual(this.token, this.idBarreno, this.armarMuestraManual())
      .subscribe((data:any) => {
        this.enviarMuestraAgregada.emit(data);
        this.limpiarFormulario();
        $('#agregarMuestra').modal('hide');
        this.spinnerService.cerrarSpinner();
      },
      (error:any) => {
        console.log(error);
        this.spinnerService.cerrarSpinner();
      });
    }
  }

  armarMuestra(){
    return {
      barrenoEntityId: this.idBarreno,
      tipoMuestraEntityId: this.listaTipoMuestras.find(tm => tm.clave === "MAQ").id,
      de: parseFloat(this.formAgregarMuestra.value.de),
      a: parseFloat(this.formAgregarMuestra.value.a),
      comentarios: this.formAgregarMuestra.value.comentarios,
      numeroCaja: this.formAgregarMuestra.value.numeroCaja
    };
  }

  armarMuestraManual(){
    if(this.formAgregarMuestra.value.muestra === ''){
      return {
        tipoMuestraEntityId: this.formAgregarMuestra.value.tipoMuestraEntityId,
        de: parseFloat(this.formAgregarMuestra.value.de),
        a: parseFloat(this.formAgregarMuestra.value.a),
        comentarios: this.formAgregarMuestra.value.comentarios,
        numeroCaja: this.formAgregarMuestra.value.numeroCaja,
        numOrden: parseInt(this.formAgregarMuestra.value.numOrden),
      }
    }
    else {
      return {
        tipoMuestraEntityId: this.formAgregarMuestra.value.tipoMuestraEntityId,
        de: parseFloat(this.formAgregarMuestra.value.de),
        a: parseFloat(this.formAgregarMuestra.value.a),
        comentarios: this.formAgregarMuestra.value.comentarios,
        numeroCaja: this.formAgregarMuestra.value.numeroCaja,
        numOrden: parseInt(this.formAgregarMuestra.value.numOrden),
        muestraDuplicadaId: this.formAgregarMuestra.value.muestra
      }
    }
  }

  limpiarFormulario(){
    this.formAgregarMuestra.controls['barrenoEntityId'].setValue('');
    this.formAgregarMuestra.controls['tipoMuestraEntityId'].setValue('');
    this.formAgregarMuestra.controls['de'].setValue('0');
    this.formAgregarMuestra.controls['a'].setValue('0');
    this.formAgregarMuestra.controls['numeroCaja'].setValue('');
    this.formAgregarMuestra.controls['comentarios'].setValue('');
    this.formAgregarMuestra.controls['muestra'].setValue('');
    this.formAgregarMuestra.controls['numOrden'].setValue('0');
  }

  cambioTipoMuestra(tipo:any){
    this.formAgregarMuestra.controls['muestra'].setValue('');
    this.mostrarOpcionesMuestra = this.listaTipoMuestrasManual.find(muestra => muestra.clave === 'MD').id == tipo ? true : false;
  }
}
