import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IRestablecerContrasena, INuevoRestablecerContrasena } from 'src/app/interfaces';
import { SpinnerService } from '../../servicios/spinner.service';
import { UsuariosService } from '../../servicios/usuarios.service';
import { SesionService } from '../../servicios/sesion.service';

declare var $:any;

@Component({
  selector: 'app-restablecer-contrasena',
  templateUrl: './restablecer-contrasena.component.html',
  styleUrls: ['./restablecer-contrasena.component.css']
})
export class RestablecerContrasenaComponent implements OnInit {

  private formularioRestablecerContrasena:FormGroup;
  private parametroGuid:string;
  private parametroCode:string;
  private tituloMensaje:string;
  private contenidoMensaje:string;

  constructor(private route:ActivatedRoute, private router:Router, private formBuilder:FormBuilder, private spinnerService:SpinnerService, private usuarioService:UsuariosService, private sesionService:SesionService){
    this.formularioRestablecerContrasena = formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/)])],
      password: ['', Validators.compose([Validators.required])]
    });
    this.parametroGuid = '';
    this.parametroCode = '';
    this.tituloMensaje = '';
    this.contenidoMensaje = '';
  }

  ngOnInit(){
    this.obtenerParametrosRuta();
  }

  restablecerContrasena(){
    this.spinnerService.cargandoSpinner();
    const objetoRestablecerContrasena:INuevoRestablecerContrasena = {
      idUser: decodeURIComponent(this.parametroGuid).replace(/ /g, '+'),
      code: decodeURIComponent(this.parametroCode).replace(/ /g, '+'),
      password: this.formularioRestablecerContrasena.value.password,
    };
    this.usuarioService.restablecerContrasena(objetoRestablecerContrasena)
    .subscribe(() => {
      this.sesionService.login(this.formularioRestablecerContrasena.value)
      .subscribe((sesion:any) => {
        localStorage.setItem('token', sesion.token);
        localStorage.setItem('role', sesion.role.toLowerCase());
        this.router.navigate(['lotes']);
        this.spinnerService.cerrarSpinner();
      },
      (error:any) => {
        console.log(error);
        this.spinnerService.cerrarSpinner();
      });
    },
    (error:any) => {
      this.tituloMensaje = 'Error Restablecer Contraseña';
      this.contenidoMensaje = `${JSON.parse(error.error).message}`;
      this.spinnerService.cerrarSpinner();
      $('#mensajeModal').modal('show');
    });
  }

  obtenerParametrosRuta(){
    this.route.params.subscribe((data:IRestablecerContrasena) => {
      this.parametroGuid = data.guid;
      this.parametroCode = data.code;
    });
  }
}
