import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FocusService {

  private subject = new Subject<{abierto:boolean}>();

  constructor(){}

  modalAbierto(estado:boolean){
    this.subject.next({ abierto: estado });
  }

  limpiar(){
    this.subject.next({ abierto: false });
  }

  obtenerEstado():Observable<any> {
    return this.subject.asObservable();
  }
}
