import { Component, OnInit } from '@angular/core';
import { UsuariosService } from '../../../servicios/usuarios.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IUsuario } from '../../../interfaces';

declare var $:any;
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  private token:string;
  private listaUsuarios:Array<IUsuario>;
  private tituloAlerta:string;
  private contenidoAlerta:string;
  private textoBotonAlerta:string;
  private idUsuarioEliminar:string;

  constructor(private usuariosService:UsuariosService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.token = localStorage.getItem('token');
    this.tituloAlerta = '';
    this.contenidoAlerta = '';
    this.textoBotonAlerta = 'Eliminar';
    this.idUsuarioEliminar = '';
  }

  ngOnInit(){
    this.spinnerService.cargandoSpinner();
    this.obtenerUsuariosAdministracion();
  }

  obtenerUsuariosAdministracion(){
    this.usuariosService.obtenerUsuarios(this.token)
    .subscribe((data:Array<IUsuario>) => {
      this.listaUsuarios = data;
      this.spinnerService.cerrarSpinner();
    });
  }

  agregarUsuarioAdministracion(){
    $('#agregarUsuario').modal('show');
    this.focusService.modalAbierto(true);
  }

  eliminarUsuarioAdministracion(idUsuario:string){
    this.usuariosService.eliminarUsuario(this.token, idUsuario)
    .subscribe(() => {
      this.eliminarUsuarioLista(idUsuario);
    },
    ((error:any) => {
      console.log(error);
    }));
  }

  obtenerNuevoUsuario(usuario:IUsuario){
    this.listaUsuarios.push(usuario);
  }

  eliminarUsuarioLista(idUsuario:string){
    const indiceEstadoEncontrado = this.listaUsuarios.findIndex(usuario => usuario.id === idUsuario);
    this.listaUsuarios.splice(indiceEstadoEncontrado, 1);
    $('#alertaModal').modal('hide');
  }

  mostrarAlertaEliminarUsuario(idUsuario:string){
    $('#alertaModal').modal('show');
    this.tituloAlerta = 'Eliminar Usuario';
    this.contenidoAlerta = '¿Realmente desea eliminar el usuario?';
    this.textoBotonAlerta = 'Eliminar';
    this.idUsuarioEliminar = idUsuario;
  }
}
