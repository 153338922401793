import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dominio } from '../dominio';

@Injectable({
  providedIn: 'root'
})
export class LotesService {

  constructor(private http:HttpClient){ }

  public obtenerLotes(token:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/lote`, cabeceras);
  }

  public obtenerDetalleLote(token:string, idLote:number){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/lote/${idLote}`, cabeceras);
  }

  public agregarArchivosLote(token:string, idLote:number, archivos:FormData){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/Lote/UploadFilesToLote/${idLote}`, archivos, cabeceras)
  }

  public obtenerArchivosLote(token:string, idLote:number){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/Lote/GetFilesLote/${idLote}`, cabeceras);
  }

  public descargarArchivoLote(token:string, idLote:number, nombreArchivo:string){
    return this.http.get(`${dominio}/Lote/DownloadFileLote/${idLote}/${nombreArchivo}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}` 
      })
    });
  }

  public eliminarArchivoLote(token:string, idLote:number, nombreArchivo:string){
    return this.http.delete(`${dominio}/Lote/DeleteFileLote/${idLote}/${nombreArchivo}`, {
      responseType: 'text',
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    });
  }


  public registrarLote(token:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/lote`, {} ,cabeceras);
  }

  public eliminarLote(token:string, idLote:number){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.delete(`${dominio}/lote/${idLote}`, cabeceras);
  }

  public editStatusLote(token: string, idLote: number, lote:any) {
    const cabeceras = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }

    return this.http.put(`${dominio}/lote/${idLote}`, lote, cabeceras);
  }
}
