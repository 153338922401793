import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ver-comentario-modal',
  templateUrl: './ver-comentario-modal.component.html',
  styleUrls: ['./ver-comentario-modal.component.css']
})
export class VerComentarioModalComponent implements OnInit {

  @Input() private nombreMuestra:string;
  @Input() private comentarioMuestra:string;

  constructor(){
    this.nombreMuestra = '';
    this.comentarioMuestra = '';
  }

  ngOnInit(){}
}
