import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-agregar-lote-modal',
  templateUrl: './agregar-lote-modal.component.html',
  styleUrls: ['./agregar-lote-modal.component.css']
})
export class AgregarLoteModalComponent implements OnInit {

  @Input() private token:string;
  private formAgregarLote:FormGroup;

  constructor(private formBuilder:FormBuilder){
    this.formAgregarLote = this.formBuilder.group({
      nombre: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
    });
  }

  ngOnInit(){}

  accionLote(){
    console.log('Vamos a agregar un lote');
  }

  limpiarFormulario(){
    this.formAgregarLote.controls['nombre'].setValue('');
  }
}
