import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dominio } from '../dominio';
import { INuevaRegion, IRegion } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class RegionesService {

  constructor(private http:HttpClient){ }

  public obtenerRegiones(token:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/region`, cabeceras);
  }

  public registrarRegion(token:string, idEstado:string, region:INuevaRegion){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/region/${idEstado}`, region, cabeceras);
  }

  public actualizarRegion(token:string, region:IRegion){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.put(`${dominio}/region/${region.id}`, region, cabeceras);
  }

  public eliminarRegion(token:string, idRegion:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.delete(`${dominio}/region/${idRegion}`, cabeceras);
  }
}
