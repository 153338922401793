import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { RegionesService } from '../../../servicios/regiones.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IRegion, IEstado } from 'src/app/interfaces';

declare var $:any;

@Component({
  selector: 'app-agregar-region-modal',
  templateUrl: './agregar-region-modal.component.html',
  styleUrls: ['./agregar-region-modal.component.css']
})
export class AgregarRegionModalComponent implements OnInit {

  @ViewChild('inputRegion', { static: true }) inputRegion:ElementRef;
  @Input() private token:string;
  @Input() private datosEditarRegion:IRegion;
  @Input() private listaEstados:Array<IEstado>;
  @Output() private enviarRegionAgregada:EventEmitter<IRegion>;
  @Output() private enviarRegionEditada:EventEmitter<IRegion>;
  @Output() private cambiarValorRegionEditada:EventEmitter<any>;
  private formAgregarRegion:FormGroup;
  private idRegion:string;
  private mostrarSelectEstados:boolean;

  constructor(private formBuilder:FormBuilder, private regionesService:RegionesService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.idRegion = '';
    this.listaEstados = [];
    this.mostrarSelectEstados = true;
    this.enviarRegionAgregada = new EventEmitter<IRegion>();
    this.enviarRegionEditada = new EventEmitter<IRegion>();
    this.cambiarValorRegionEditada = new EventEmitter<any>();
    this.formAgregarRegion = formBuilder.group({
      estado: ['', Validators.compose([Validators.required])],
      region: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
    });

    this.focusService.obtenerEstado()
    .subscribe((estado:any) => {
      if(estado.abierto){
        setTimeout(() => {
          this.inputRegion.nativeElement.focus();
        }, 500);
      }
    });
  }

  ngOnInit(){}

  ngOnChanges(){
    if(this.datosEditarRegion !== undefined){
      this.establecerRegionEditar();
      this.mostrarSelectEstados = false;  // Se usa una bandera para mostrar el 'select' de estados solo cuando se va a registrar una región
      this.formAgregarRegion.controls['estado'].clearValidators();        // Se limpia la validación del campo 'estado' para que no se requerido
      this.formAgregarRegion.controls['estado'].updateValueAndValidity(); // cuando se edite la región (porque no aparece la opción de agregar estado)
    }
  }

  registrarRegion(){
    this.spinnerService.cargandoSpinner();
    this.regionesService.registrarRegion(this.token, this.formAgregarRegion.value.estado, { region: this.formAgregarRegion.value.region })
    .subscribe((data:IRegion) => {
      this.enviarRegionAgregada.emit(data);
      this.limpiarFormulario();
      $('#agregarRegion').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  editarRegion(){
    this.spinnerService.cargandoSpinner();
    this.regionesService.actualizarRegion(this.token, { id: this.idRegion, region: this.formAgregarRegion.value.region })
    .subscribe((data:IRegion) => {
      this.enviarRegionEditada.emit(data);
      this.limpiarFormulario();
      $('#agregarRegion').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    ((error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    }));
  }

  accionRegion(){
    if(this.datosEditarRegion !== undefined){
      this.editarRegion();
    }
    else {
      this.registrarRegion();
    }
  }

  limpiarFormulario(){
    this.idRegion = '';
    this.formAgregarRegion.controls['estado'].setValue('');
    this.formAgregarRegion.controls['region'].setValue('');
    this.mostrarSelectEstados = true; // Se cambia la bandera a 'true' para que se muestre el select de estados por si se necesita registrar una región (en editar no se necesita el select)
    this.formAgregarRegion.controls['estado'].setValidators([Validators.required]); // Una vez que se cierra la modal, se vuelve a agregar la validación de requerido a el campo
    this.formAgregarRegion.controls['estado'].updateValueAndValidity();             // 'estado' por si se necesita registrar una región (se necesita seleccionar un estado).
    this.cambiarValorRegionEditada.emit(undefined);
  }

  establecerRegionEditar(){
    this.idRegion = this.datosEditarRegion.id;
    this.formAgregarRegion.controls['region'].setValue(this.datosEditarRegion.region);
  }
}
