import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LotesService } from '../../../servicios/lotes.service'
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { ILote } from 'src/app/interfaces';

declare var $: any;

@Component({
  selector: 'app-editar-status-lote',
  templateUrl: './editar-status-lote.component.html',
  styleUrls: ['./editar-status-lote.component.css']
})
export class EditarStatusLoteComponent implements OnInit {
  @ViewChild('inputAnalisisQuimico', { static: true }) inputAnalisisQuimico: ElementRef;
  @Input() private token: string;
  @Input() private lote: ILote;
  @Output() private enviarStatusLote: EventEmitter<any>;
  @Output() private cambiarStatusLote: EventEmitter<any>;

  private formEditarStatusLote: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private lotesService: LotesService,
    private focusService: FocusService,
    private spinnerService: SpinnerService) {

    this.formEditarStatusLote = this.formBuilder.group({
      analisisQuimico: ['0', Validators.compose([])],
      enviada: ['0', Validators.compose([])]
    });

    this.enviarStatusLote = new EventEmitter<any>();
    this.cambiarStatusLote = new EventEmitter<any>();

    this.focusService.obtenerEstado()
      .subscribe((estado: any) => {
        if (estado.abierto) {
          setTimeout(() => {
            this.inputAnalisisQuimico.nativeElement.focus();
          }, 500);
        }
      });

  }

  ngOnInit() {
  }   

  ngOnChanges() {
    if (this.lote !== undefined) {
      this.establecerStatusLote();
    }
  }

  guardarStausLote() {
    this.spinnerService.cargandoSpinner();
    this.lotesService.editStatusLote(this.token, this.lote.id, this.armarLote())
      .subscribe((data: ILote) => {
        console.log(data);
        this.enviarStatusLote.emit(data);
        this.limpiarFormulario();
        $('#editarStatusLote').modal('hide');
        this.spinnerService.cerrarSpinner();
      },
        (error: any) => {
          console.log(error);
          this.spinnerService.cerrarSpinner();
        });
  }
  armarLote() {
    let modelStatusLote = {
      analisisQuimico: this.formEditarStatusLote.value.analisisQuimico,
      enviada: this.formEditarStatusLote.value.enviada
    }
    console.log(modelStatusLote)
    return {
      analisisQuimico: this.formEditarStatusLote.value.analisisQuimico,
      enviada: this.formEditarStatusLote.value.enviada,
    };
  }
  establecerStatusLote() {
    this.formEditarStatusLote.controls['analisisQuimico'].setValue(this.lote.analisisQuimico);
    this.formEditarStatusLote.controls['enviada'].setValue(this.lote.enviada);
  }

  limpiarFormulario() {
    this.formEditarStatusLote.controls['analisisQuimico'].setValue(false);
    this.formEditarStatusLote.controls['enviada'].setValue(false);
    this.cambiarStatusLote.emit(undefined);
  }
}
