import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dominio } from '../dominio';
import { INuevoComposito } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CompositosService {

  constructor(private http:HttpClient){}

  public obtenerCompositos(token:string, idBarreno:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/composito/barrenocompositos/${idBarreno}`, cabeceras);
  }

  public registrarComposito(token:string, composito:INuevoComposito, idBarreno:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/composito/${idBarreno}`, composito, cabeceras);
  }

  public editarComposito(token:string, composito:INuevoComposito, idComposito:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.put(`${dominio}/composito/${idComposito}`, composito, cabeceras);
  }

  public eliminarComposito(token:string, idComposito:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.delete(`${dominio}/composito/${idComposito}`, cabeceras);
  }
}
