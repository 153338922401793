import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EstadosService } from '../../../servicios/estados.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IEstado } from '../../../interfaces';

declare var $:any;

@Component({
  selector: 'app-agregar-estado-modal',
  templateUrl: './agregar-estado-modal.component.html',
  styleUrls: ['./agregar-estado-modal.component.css']
})
export class AgregarEstadoModalComponent implements OnInit {

  @ViewChild('inputEstado', { static: true }) inputEstado:ElementRef;
  @Input() private token:string;
  @Input() private datosEditarEstado:IEstado;
  @Output() private enviarEstadoAgregado:EventEmitter<IEstado>;
  @Output() private enviarEstadoEditado:EventEmitter<IEstado>;
  @Output() private cambiarValorEstadoEditado:EventEmitter<any>;
  private formAgregarEstado:FormGroup;
  private idEstado:string;

  constructor(private formBuilder:FormBuilder, private EstadosService:EstadosService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.idEstado = '';
    this.enviarEstadoAgregado = new EventEmitter<IEstado>();
    this.enviarEstadoEditado = new EventEmitter<IEstado>();
    this.cambiarValorEstadoEditado = new EventEmitter<any>();
    this.formAgregarEstado = this.formBuilder.group({
      estado: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
    });

    this.focusService.obtenerEstado()
    .subscribe((estado:any) => {
      if(estado.abierto){
        setTimeout(() => {
          this.inputEstado.nativeElement.focus();
        }, 500);
      }
    });
  }

  ngOnInit(){}

  ngOnChanges(){
    if(this.datosEditarEstado !== undefined){
      this.establecerEstadoEditar();
    }
  }

  registrarEstado(){
    this.spinnerService.cargandoSpinner();
    this.EstadosService.registrarEstado(this.token, { estado: this.formAgregarEstado.value.estado })
    .subscribe((data:IEstado) => {
      this.enviarEstadoAgregado.emit(data);
      this.limpiarFormulario();
      $('#agregarEstado').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    ((error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    }));
  }

  editarEstado(){
    this.spinnerService.cargandoSpinner();
    this.EstadosService.actualizarEstado(this.token, { id: this.idEstado, estado: this.formAgregarEstado.value.estado })
    .subscribe((data:IEstado) => {
      this.enviarEstadoEditado.emit(data);
      this.limpiarFormulario();
      $('#agregarEstado').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    ((error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    }));
  }

  accionEstado(){
    if(this.datosEditarEstado !== undefined){
      this.editarEstado();
    }
    else {
      this.registrarEstado();
    }
  }

  limpiarFormulario(){
    this.idEstado = '';
    this.formAgregarEstado.controls['estado'].setValue('');
    this.cambiarValorEstadoEditado.emit(undefined);
  }

  establecerEstadoEditar(){
    this.idEstado = this.datosEditarEstado.id;
    this.formAgregarEstado.controls['estado'].setValue(this.datosEditarEstado.estado);
  }
}
