import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UsuariosService } from '../../../servicios/usuarios.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IUsuario } from 'src/app/interfaces';

declare var $:any;

@Component({
  selector: 'app-agregar-usuario-modal',
  templateUrl: './agregar-usuario-modal.component.html',
  styleUrls: ['./agregar-usuario-modal.component.css']
})
export class AgregarUsuarioModalComponent implements OnInit {

  @ViewChild('inputNombre', { static: true }) inputNombre:ElementRef;
  @Input() private token:string;
  @Output() private enviarUsuarioAgregado:EventEmitter<IUsuario>;
  private formAgregarUsuario:FormGroup;
  private expresionRegularCorreo:any;

  constructor(private formBuilder:FormBuilder, private usuarioService:UsuariosService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.expresionRegularCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/;
    this.enviarUsuarioAgregado = new EventEmitter<IUsuario>();
    this.formAgregarUsuario = this.formBuilder.group({
      nombre: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
      apellidos: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.expresionRegularCorreo)])],
    });

    this.focusService.obtenerEstado()
    .subscribe((estado:any) => {
      if(estado.abierto){
        setTimeout(() => {
          this.inputNombre.nativeElement.focus();
        }, 500);
      }
    });
  }

  ngOnInit(){}

  registrarUsuario(){
    this.spinnerService.cargandoSpinner();
    this.usuarioService.registrarUsuario(this.token, this.formAgregarUsuario.value)
    .subscribe((data:IUsuario) => {
      this.enviarUsuarioAgregado.emit(data);
      this.limpiarFormulario();
      $('#agregarUsuario').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  limpiarFormulario(){
    this.formAgregarUsuario.controls['nombre'].setValue('');
    this.formAgregarUsuario.controls['apellidos'].setValue('');
    this.formAgregarUsuario.controls['email'].setValue('');
  }
}
