import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { DefaultComponent } from './layouts/default/default.component';
import { AplicacionComponent } from './layouts/aplicacion/aplicacion.component';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { SpinnerComponent } from './components/spinner/spinner.component';

import { HomeComponent } from './views/home/home.component';
import { OlvidoContrasenaComponent } from './views/olvido-contrasena/olvido-contrasena.component';
import { EstablecerContrasenaComponent } from './views/establecer-contrasena/establecer-contrasena.component';
import { RestablecerContrasenaComponent } from './views/restablecer-contrasena/restablecer-contrasena.component';
import { NoEncontradoComponent } from './views/no-encontrado/no-encontrado.component';

import { LotesComponent } from './views/administracion/lotes/lotes.component';
import { BarrenosComponent } from './views/administracion/barrenos/barrenos.component';
import { CompositosComponent } from './views/administracion/compositos/compositos.component';
import { MuestrasComponent } from './views/administracion/muestras/muestras.component';
import { EstadosComponent } from './views/administracion/estados/estados.component';
import { RegionesComponent } from './views/administracion/regiones/regiones.component';
import { ProyectosComponent } from './views/administracion/proyectos/proyectos.component';
import { UsuariosComponent } from './views/administracion/usuarios/usuarios.component';

import { BarrenoComponent } from './views/usuario/barreno/barreno.component';
import { MuestraComponent } from './views/usuario/muestra/muestra.component';

import { AlertaModalComponent } from './components/modals/alerta-modal/alerta-modal.component';
import { AgregarEstadoModalComponent } from './components/modals/agregar-estado-modal/agregar-estado-modal.component';
import { AgregarRegionModalComponent } from './components/modals/agregar-region-modal/agregar-region-modal.component';
import { AgregarProyectoModalComponent } from './components/modals/agregar-proyecto-modal/agregar-proyecto-modal.component';
import { AgregarUsuarioModalComponent } from './components/modals/agregar-usuario-modal/agregar-usuario-modal.component';
import { AgregarBarrenoModalComponent } from './components/modals/agregar-barreno-modal/agregar-barreno-modal.component';
import { AgregarCompositoModalComponent } from './components/modals/agregar-composito-modal/agregar-composito-modal.component';
import { AgregarLoteModalComponent } from './components/modals/agregar-lote-modal/agregar-lote-modal.component';
import { AgregarMuestraModalComponent } from './components/modals/agregar-muestra-modal/agregar-muestra-modal.component';
import { EditarMuestraModalComponent } from './components/modals/editar-muestra-modal/editar-muestra-modal.component';
import { VerComentarioModalComponent } from './components/modals/ver-comentario-modal/ver-comentario-modal.component';
import { MensajeModalComponent } from './components/modals/mensaje-modal/mensaje-modal.component';

import { SesionService } from './servicios/sesion.service';
import { EstadosService } from './servicios/estados.service';
import { RegionesService } from './servicios/regiones.service';
import { ProyectosService } from './servicios/proyectos.service';
import { UsuariosService } from './servicios/usuarios.service';

import { LoginGuard } from './guards/login.guard';
import { LoginAdministracionGuard } from './guards/login-administracion.guard';
import { LoginUsuarioGuard } from './guards/login-usuario.guard';

import { LottieAnimationViewModule } from 'ng-lottie';
import { EditarStatusLoteComponent } from './components/modals/editar-status-lote/editar-status-lote.component';
import { SubirArchivosLoteModalComponent } from './components/modals/subir-archivos-lote-modal/subir-archivos-lote-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    DefaultComponent,
    AplicacionComponent,
    EstadosComponent,
    EstablecerContrasenaComponent,
    NoEncontradoComponent,
    UsuariosComponent,
    BarrenoComponent,
    MuestraComponent,
    AgregarEstadoModalComponent,
    AlertaModalComponent,
    AgregarUsuarioModalComponent,
    RegionesComponent,
    ProyectosComponent,
    AgregarRegionModalComponent,
    AgregarProyectoModalComponent,
    BarrenosComponent,
    AgregarBarrenoModalComponent,
    LotesComponent,
    AgregarLoteModalComponent,
    MuestrasComponent,
    AgregarMuestraModalComponent,
    EditarMuestraModalComponent,
    SpinnerComponent,
    CompositosComponent,
    AgregarCompositoModalComponent,
    VerComentarioModalComponent,
    OlvidoContrasenaComponent,
    MensajeModalComponent,
    RestablecerContrasenaComponent,
    EditarStatusLoteComponent,
    SubirArchivosLoteModalComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LottieAnimationViewModule.forRoot(),
    RouterModule.forRoot([
      {
        path: '',
        component: HomeComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'lotes',
        component: LotesComponent,
        canActivate: [LoginAdministracionGuard]
      },
      {
        path: 'lotes/:id',
        component: BarrenosComponent,
        canActivate: [LoginAdministracionGuard]
      },
      {
        path: 'lotes/:id/compositos/:id',
        component: CompositosComponent,
        canActivate: [LoginAdministracionGuard]
      },
      {
        path: 'lotes/:id/:id',
        component: MuestrasComponent,
        canActivate: [LoginAdministracionGuard]
      },
      {
        path: 'estados',
        component: EstadosComponent,
        canActivate: [LoginAdministracionGuard]
      },
      {
        path: 'regiones',
        component: RegionesComponent,
        canActivate: [LoginAdministracionGuard]
      },
      {
        path: 'proyectos',
        component: ProyectosComponent,
        canActivate: [LoginAdministracionGuard]
      },
      {
        path: 'usuarios',
        component: UsuariosComponent,
        canActivate: [LoginAdministracionGuard]
      },
      {
        path: 'establecer-contrasena/:guid/:code',
        component: EstablecerContrasenaComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'olvido-contrasena',
        component: OlvidoContrasenaComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'restablecer-contrasena/:guid/:code',
        component: RestablecerContrasenaComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'no-encontrado',
        component: NoEncontradoComponent,
      },
      {
        path: '**',
        redirectTo: '/no-encontrado'
      }
    ])
  ],
  providers: [
    SesionService,
    EstadosService,
    RegionesService,
    ProyectosService,
    UsuariosService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
