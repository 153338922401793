import { Component, OnInit } from '@angular/core';
import { LotesService } from '../../../servicios/lotes.service';
import { ILotes, ILote, ITipoMuestra } from '../../../interfaces';
import { FocusService } from '../../../servicios/focus.service';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../servicios/spinner.service';

declare var $:any;

@Component({
  selector: 'app-lotes',
  templateUrl: './lotes.component.html',
  styleUrls: ['./lotes.component.css']
})
export class LotesComponent implements OnInit {

  private token: string;
  private lote: ILote;
  private listaLotes:Array<ILote>;
  private listaTipoMuestras:Array<ITipoMuestra>;
  private tituloAlerta:string;
  private contenidoAlerta:string;
  private textoBotonAlerta:string;
  private idLoteEliminar:number;
  private esAdministrador:boolean;

  constructor(private lotesService: LotesService, private router: Router, private focusService: FocusService, private spinnerService:SpinnerService){
    this.token = localStorage.getItem('token');
    this.esAdministrador = localStorage.getItem('role') === 'usuario' ? false : true;
    this.listaLotes = [];
    this.listaTipoMuestras = [];
    this.tituloAlerta = '';
    this.contenidoAlerta = '';
    this.textoBotonAlerta = 'Eliminar';
    this.idLoteEliminar = null;
  }

  ngOnInit(){
    this.spinnerService.cargandoSpinner();
    this.obtenerLotesAdministracion();
  }

  obtenerLotesAdministracion(){
    this.lotesService.obtenerLotes(this.token)
    .subscribe((data:ILotes) => {
      this.listaLotes = data.lotes;
      this.listaTipoMuestras = data.tiposMuestras;
      this.spinnerService.cerrarSpinner();
    });
  }

  agregarLoteAdministracion(){
    this.spinnerService.cargandoSpinner();
    this.lotesService.registrarLote(this.token)
    .subscribe((data:ILote) => {
      this.listaLotes.unshift(data);
      this.spinnerService.cerrarSpinner();
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  eliminarLoteAdministracion(idLote:number){
    this.lotesService.eliminarLote(this.token, idLote)
    .subscribe(() => {
      this.eliminarLoteLista(idLote);
    },
    (error:any) => {
      console.log(error);
    });
  }

  mostrarBarrenos(idLote:number){
    this.router.navigate(['lotes', idLote]);
  }

  eliminarLoteLista(idLote:number){
    const indiceLoteEncontrado = this.listaLotes.findIndex(lote => lote.id === idLote);
    this.listaLotes.splice(indiceLoteEncontrado, 1);
    $('#alertaModal').modal('hide');
  }

  mostrarAlertaEliminarLote(idLote:number){
    $('#alertaModal').modal('show');
    this.tituloAlerta = 'Eliminar Lote';
    this.contenidoAlerta = '¿Realmente desea eliminar el lote?';
    this.textoBotonAlerta = 'Eliminar';
    this.idLoteEliminar = idLote;
  }

  mostrarEditarLote(lote:ILote) {
    $('#editarStatusLote').modal('show');
    this.lote = lote;
    this.focusService.modalAbierto(true);
  }

  recibirStatusLote(lote: ILote) {
    this.remplazarLote(lote);
  }


  remplazarLote(lote: ILote) {
    const indiceLoteEncontrado = this.listaLotes.findIndex(l => l.id === lote.id);
    this.listaLotes.splice(indiceLoteEncontrado, 1, lote);
  }
}
