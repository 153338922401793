import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CompositosService } from '../../../servicios/compositos.service';
import { FocusService } from '../../../servicios/focus.service';
import { IComposito } from '../../../interfaces';

declare var $:any;

@Component({
  selector: 'app-compositos',
  templateUrl: './compositos.component.html',
  styleUrls: ['./compositos.component.css']
})
export class CompositosComponent implements OnInit {

  private idBarreno:string;
  private token:string;
  private tituloAlerta:string;
  private contenidoAlerta:string;
  private textoBotonAlerta:string;
  private idCompositoEliminar:string;
  private datosEditarComposito:IComposito;
  private esAdministrador:boolean;

  private listaCompositos:Array<IComposito>;

  constructor(private route:ActivatedRoute, private location:Location, private compositoService:CompositosService, private focusService:FocusService){
    this.token = localStorage.getItem('token');
    this.esAdministrador = localStorage.getItem('role') === 'usuario' ? false : true;
    this.listaCompositos = [];
  }

  ngOnInit(){
    this.obtenerParametrosRuta();
    this.obtenerListaCompositos();
  }

  obtenerListaCompositos(){
    this.compositoService.obtenerCompositos(this.token, this.idBarreno)
    .subscribe((compositos:Array<IComposito>) => {
      this.listaCompositos = compositos;
    },
    (error:any) => {
      console.log(error);
    });
  }

  agregarCompositoAdministracion(){
    $('#agregarComposito').modal('show');
    this.datosEditarComposito = undefined;
    this.focusService.modalAbierto(true);
  }

  editarCompositoAdministracion(composito:IComposito){
    $('#agregarComposito').modal('show');
    this.datosEditarComposito = composito;
    this.focusService.modalAbierto(true);
  }

  eliminarCompositoAdministracion(idComposito:string){
    this.compositoService.eliminarComposito(this.token, idComposito)
    .subscribe(() => {
      this.eliminarCompositoLista(idComposito);
    },
    (error:any) => {
      console.log(error);
    });
  }

  mostrarAlertaEliminar(idComposito:string){
    $('#alertaModal').modal('show');
    this.tituloAlerta = 'Eliminar Compuesto';
    this.contenidoAlerta = '¿Realmente desea eliminar el composito?';
    this.textoBotonAlerta = 'Eliminar';
    this.idCompositoEliminar = idComposito;
  }

  compositoAgregado(composito:IComposito){
    this.listaCompositos.push(composito);
  }

  compositoEditado(composito:IComposito){
    const indiceComposito = this.listaCompositos.findIndex(valor => valor.id === composito.id);
    this.listaCompositos.splice(indiceComposito, 1, composito);
  }

  eliminarCompositoLista(idComposito:string){
    const indiceComposito = this.listaCompositos.findIndex(composito => composito.id === idComposito);
    this.listaCompositos.splice(indiceComposito, 1);
    $('#alertaModal').modal('hide');
  }

  obtenerParametrosRuta(){
    this.route.params
    .subscribe((data:any) => {
      this.idBarreno = data.id;
    });
  }

  regresar(){
    this.location.back();
  }
}
