import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { INuevoEstado, IEstado } from '../interfaces';
import { dominio } from '../dominio';

@Injectable({
  providedIn: 'root'
})
export class EstadosService {

  constructor(private http:HttpClient) { }

  public obtenerEstados(token:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/estado`, cabeceras);
  }

  public registrarEstado(token:string, registro:INuevoEstado){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/estado`, registro, cabeceras);
  }

  public actualizarEstado(token:string, estado:IEstado){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.put(`${dominio}/estado/${estado.id}`, estado, cabeceras);
  }

  public eliminarEstado(token:string, id:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.delete(`${dominio}/estado/${id}`, cabeceras);
  }
}
