import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginAdministracionGuard implements CanActivate {

  private tokenUsuario:string;
  private roleUsuario:string;

  constructor(private router:Router){
    this.tokenUsuario = localStorage.getItem('token');
    this.roleUsuario = localStorage.getItem('role');
  }

  canActivate(){
    if(this.tokenUsuario === null){
      this.router.navigate(['/']);
      return false;
    }
    else if(this.roleUsuario !== 'administrador' && this.roleUsuario !== 'usuario'){
      this.router.navigate(['/']);
      return false;
    }
    else {
      return true;
    }
  }
}
