import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BarrenoService } from '../../../servicios/barreno.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { INuevoBarreno, IProyecto, IEquipo, IBarreno } from 'src/app/interfaces';

declare var $:any;

@Component({
  selector: 'app-agregar-barreno-modal',
  templateUrl: './agregar-barreno-modal.component.html',
  styleUrls: ['./agregar-barreno-modal.component.css']
})
export class AgregarBarrenoModalComponent implements OnInit, AfterViewInit {

  @ViewChild('inputIdBarreno', { static: true }) inputIdBarreno: ElementRef;
  @Input() private token:string;
  @Input() private listaProyectos:Array<IProyecto>;
  @Input() private listaEquipos:Array<IEquipo>;
  @Input() private idLote:number;
  @Output() private enviarBarrenoAgregado:EventEmitter<IBarreno>;
  @Output() private enviarBarrenoEditado:EventEmitter<INuevoBarreno>;
  private formAgregarBarreno:FormGroup;
  private expresionNumeroDecimal:any;
  private expresionNumeroEntero:any;

  constructor(private formBuilder:FormBuilder, private barrenoService:BarrenoService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.listaProyectos = [];
    this.expresionNumeroDecimal = /^(\d|-)?(\d|,)*\.?\d*$/;
    this.expresionNumeroEntero = /^(\d|-)+$/;
    this.enviarBarrenoAgregado = new EventEmitter<IBarreno>();
    this.enviarBarrenoEditado = new EventEmitter<INuevoBarreno>();
    this.formAgregarBarreno = formBuilder.group({
      idBarreno: ['', Validators.compose([Validators.required])],
      norte: ['0', Validators.compose([Validators.pattern(this.expresionNumeroDecimal)])],
      fecha: [new Date().toISOString().slice(0,10), Validators.compose([])],
      elevacion: ['0', Validators.compose([Validators.pattern(this.expresionNumeroDecimal)])],
      idProyecto: ['', Validators.compose([Validators.required])],
      azimut: ['', Validators.compose([])],
      tipoEquipoEntityId: ['', Validators.compose([Validators.required])],
      inclinacion: ['0', Validators.compose([Validators.pattern(this.expresionNumeroDecimal)])],
      muestreadoPor: ['', Validators.compose([Validators.required])],
      pt: ['0', Validators.compose([Validators.pattern(this.expresionNumeroDecimal)])],
      este: ['0', Validators.compose([Validators.pattern(this.expresionNumeroDecimal)])],
    });

    this.focusService.obtenerEstado()
    .subscribe((valor:any) => {
      if(valor.abierto){
        setTimeout(() => {
          this.inputIdBarreno.nativeElement.focus();
          this.formAgregarBarreno.controls['tipoEquipoEntityId'].setValue(this.listaEquipos.find(equipo => equipo.abreviatura === 'BD').id);
        }, 500);
      }
    });
  }

  ngOnInit(){}

  ngAfterViewInit(){}

  registrarBarreno(){
    this.spinnerService.cargandoSpinner();
    this.barrenoService.registrarBarreno(this.token, this.idLote, this.armarModeloBarreno())
    .subscribe((data:IBarreno) => {
      this.enviarBarrenoAgregado.emit(data);
      this.limpiarFormulario();
      $('#agregarBarreno').modal('hide');
      this.spinnerService.cerrarSpinner();
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  accionBarreno(){
    this.registrarBarreno();
  }

  limpiarFormulario(){
    this.formAgregarBarreno.controls['idBarreno'].setValue('');
    this.formAgregarBarreno.controls['norte'].setValue('0');
    this.formAgregarBarreno.controls['fecha'].setValue(new Date().toISOString().slice(0,10));
    this.formAgregarBarreno.controls['elevacion'].setValue('0');
    this.formAgregarBarreno.controls['idProyecto'].setValue('');
    this.formAgregarBarreno.controls['azimut'].setValue('');
    this.formAgregarBarreno.controls['tipoEquipoEntityId'].setValue('');
    this.formAgregarBarreno.controls['inclinacion'].setValue('0');
    this.formAgregarBarreno.controls['muestreadoPor'].setValue('');
    this.formAgregarBarreno.controls['pt'].setValue('0');
    this.formAgregarBarreno.controls['este'].setValue('0');
  }

  armarModeloBarreno(){
    const barreno:INuevoBarreno = {
      azimut: this.formAgregarBarreno.value.azimut,
      elevacion: parseFloat(this.formAgregarBarreno.value.elevacion),
      este: parseFloat(this.formAgregarBarreno.value.este),
      fecha: this.formAgregarBarreno.value.fecha,
      idBarreno: this.formAgregarBarreno.value.idBarreno,
      inclinacion: parseFloat(this.formAgregarBarreno.value.inclinacion),
      muestreadoPor: this.formAgregarBarreno.value.muestreadoPor,
      norte: parseFloat(this.formAgregarBarreno.value.norte),
      pt: parseFloat(this.formAgregarBarreno.value.pt),
      tipoEquipoEntityId: this.formAgregarBarreno.value.tipoEquipoEntityId,
      proyectoEntityId: this.formAgregarBarreno.value.idProyecto,
    };
    return barreno;
  }
}
