import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IEstablecerContrasena, INuevoEstablecerContrasena } from '../../interfaces';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuariosService } from '../../servicios/usuarios.service';
import { SesionService } from '../../servicios/sesion.service';
import { SpinnerService } from '../../servicios/spinner.service';

@Component({
  selector: 'app-establecer-contrasena',
  templateUrl: './establecer-contrasena.component.html',
  styleUrls: ['./establecer-contrasena.component.css']
})
export class EstablecerContrasenaComponent implements OnInit {

  private formularioEstablecerContrasena:FormGroup;
  private parametroGuid:string;
  private parametroCode:string;

  constructor(private formBuilder:FormBuilder, private route:ActivatedRoute, private usuarioService:UsuariosService, private sesionService:SesionService, private spinnerService:SpinnerService, private router:Router){
    this.formularioEstablecerContrasena = this.formBuilder.group({
      correo: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/)])],
      contrasena: ['', Validators.compose([Validators.required])],
    });
    this.parametroGuid = '';
    this.parametroCode = '';
  }

  ngOnInit(){
    this.obtenerParametrosRuta();
  }

  establecerContrasena(){
    this.spinnerService.cargandoSpinner();
    const objetoEstablecerContrasena:INuevoEstablecerContrasena = {
      idUser: decodeURIComponent(this.parametroGuid).replace(/ /g, '+'),
      code: decodeURIComponent(this.parametroCode).replace(/ /g, '+'),
      password: this.formularioEstablecerContrasena.value.contrasena,
    };
    this.usuarioService.establecerContrasena(objetoEstablecerContrasena)
    .subscribe(() => {
      this.sesionService.login({ email: this.formularioEstablecerContrasena.value.correo, password: this.formularioEstablecerContrasena.value.contrasena })
      .subscribe((sesion:any) => {
        localStorage.setItem('token', sesion.token);
        localStorage.setItem('role', sesion.role.toLowerCase());
        this.router.navigate(['lotes']);
        this.spinnerService.cerrarSpinner();
      },
      (error:any) => {
        console.log(error);
        this.spinnerService.cerrarSpinner();
      });
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  obtenerParametrosRuta(){
    this.route.params.subscribe((data:IEstablecerContrasena) => {
      this.parametroGuid = data.guid;
      this.parametroCode = data.code;
    });
  }
}
