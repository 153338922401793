import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dominio } from '../dominio';
import { INuevaMuestra, INuevoAnalisisQuimico } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class MuestrasService {

  constructor(private http:HttpClient){ }

  public obtenerMuestras(token:string, idBarreno:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.get(`${dominio}/muestra/${idBarreno}`, cabeceras);
  }

  public registrarMuestra(token:string, idBarreno:string, muestra:any){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/muestra/${idBarreno}`, muestra, cabeceras);
  }

  public registrarMuestraManual(token:string, idBarreno:string, muestra:any){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/Muestra/InsertaMuestraManual/${idBarreno}`, muestra, cabeceras);
  }

  public registrarMuestraAutomatica(token:string, idBarreno:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/muestra/InsertaMuestrasAutomaticas/${idBarreno}`, {}, cabeceras);
  }

  public editarMuestra(token:string, idMuestra:string, analisis:INuevoAnalisisQuimico){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/muestra/updateaq/${idMuestra}`, analisis, cabeceras);
  }

  public eliminarMuestra(token:string, idMuestra:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.delete(`${dominio}/muestra/${idMuestra}`, cabeceras);
  }

  public generarIdMuestra(token:string, idBarreno:string){
    const cabeceras = { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token
    })}

    return this.http.post(`${dominio}/muestra/GenerateIdMuestra/${idBarreno}`, {}, cabeceras);
  }
}
