import { Component, OnInit } from '@angular/core';
import { RegionesService } from '../../../servicios/regiones.service';
import { ProyectosService } from '../../../servicios/proyectos.service';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { IProyecto, IRegion, IEditarProyecto } from 'src/app/interfaces';

declare var $:any;

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.css']
})
export class ProyectosComponent implements OnInit {

  private token:string;
  private listaRegiones:Array<IRegion>;
  private listaProyectos:Array<any>;
  private tituloAlerta:string;
  private contenidoAlerta:string;
  private textoBotonAlerta:string;
  private idProyectoEliminar:string;
  private datosEditarProyecto:IProyecto;

  constructor(private regionesService:RegionesService, private proyectosService:ProyectosService, private focusService:FocusService, private spinnerService:SpinnerService){
    this.token = localStorage.getItem('token');
    this.tituloAlerta = '';
    this.contenidoAlerta = '';
    this.textoBotonAlerta = 'Eliminar';
    this.idProyectoEliminar = '';
    this.datosEditarProyecto = undefined;
  }

  ngOnInit(){
    this.spinnerService.cargandoSpinner();
    this.obtenerProyectosAdministracion();
  }

  obtenerProyectosAdministracion(){
    this.proyectosService.obtenerProyectos(this.token)
    .subscribe((data:any) => {
      this.listaProyectos = data;
      this.obtenerRegiones();
      this.spinnerService.cerrarSpinner();
    });
  }

  agregarProyectoAdministracion(){
    $('#agregarProyecto').modal('show');
    this.datosEditarProyecto = undefined;
    this.focusService.modalAbierto(true);
  }

  editarProyectoAdministracion(proyecto:IProyecto){
    $('#agregarProyecto').modal('show');
    this.datosEditarProyecto = proyecto;
    this.focusService.modalAbierto(true);
  }

  eliminarProyectoAdministracion(idProyecto:string){
    this.proyectosService.eliminarProyecto(this.token, idProyecto)
    .subscribe(() => {
      this.eliminarProyectoLista(idProyecto);
    },
    (error:any) => {
      console.log(error);
    });
  }

  obtenerNuevoProyecto(proyecto:IProyecto){
    this.listaProyectos.push(proyecto);
  }

  obtenerRegiones(){
    this.regionesService.obtenerRegiones(this.token)
    .subscribe((data:Array<IRegion>) => {
      this.listaRegiones = data;
    });
  }

  obtenerProyectoEditado(proyectoEditado:IEditarProyecto){
    const indiceProyectoEncontrado = this.listaProyectos.findIndex(proyecto => proyecto.id === proyectoEditado.id);
    this.listaProyectos.splice(indiceProyectoEncontrado, 1, proyectoEditado);
  }

  eliminarProyectoLista(idProyecto:string){
    const indiceProyectoEncontrado = this.listaProyectos.findIndex(proyecto => proyecto.id === idProyecto);
    this.listaProyectos.splice(indiceProyectoEncontrado, 1);
    $('#alertaModal').modal('hide');
  }

  mostrarAlertaEliminarProyecto(idProyecto){
    $('#alertaModal').modal('show');
    this.tituloAlerta = 'Eliminar Proyecto';
    this.contenidoAlerta = '¿Realmente desea eliminar el proyecto?';
    this.textoBotonAlerta = 'Eliminar';
    this.idProyectoEliminar = idProyecto;
  }

  cambiarValorProyecto(valor:any){
    this.datosEditarProyecto = undefined;
  }
}
