import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuariosService } from '../../servicios/usuarios.service';
import { SpinnerService } from '../../servicios/spinner.service';

declare var $:any;

@Component({
  selector: 'app-olvido-contrasena',
  templateUrl: './olvido-contrasena.component.html',
  styleUrls: ['./olvido-contrasena.component.css']
})
export class OlvidoContrasenaComponent implements OnInit {

  private formularioOlvidoContrasena:FormGroup;
  private tituloMensaje:string;
  private contenidoMensaje:string;

  constructor(private formBuilder:FormBuilder, private usuarioService:UsuariosService, private spinnerService:SpinnerService){
    this.formularioOlvidoContrasena = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/)])]
    });
    this.tituloMensaje = '';
    this.contenidoMensaje = '';
  }

  ngOnInit(){}

  enviarCorreoCambiarContrasena(){
    this.spinnerService.cargandoSpinner();
    this.usuarioService.olvidoContrasena(this.formularioOlvidoContrasena.value)
    .subscribe(() => {
      this.tituloMensaje = 'Correo Enviado';
      this.contenidoMensaje = `Se envió un enlace al correo ${this.formularioOlvidoContrasena.value.email} para que pueda cambiar su contraseña.`;
      this.formularioOlvidoContrasena.controls['email'].setValue('');
      this.spinnerService.cerrarSpinner();
      $('#mensajeModal').modal('show');
    },
    (error:any) => {
      this.tituloMensaje = 'Ocurrió un error';
      this.contenidoMensaje = 'Ocurrió un error al enviar el correo, intentelo de nuevo o mas tarde';
      this.spinnerService.cerrarSpinner();
      $('#mensajeModal').modal('show');
    });
  }
}
