import { Component, OnInit } from '@angular/core';
import { LotesService } from '../../../servicios/lotes.service';
import { BarrenoService } from '../../../servicios/barreno.service';
import { ProyectosService } from '../../../servicios/proyectos.service';
import { IBarreno, IProyecto, IEquipo, ILote } from 'src/app/interfaces';
import { FocusService } from '../../../servicios/focus.service';
import { SpinnerService } from '../../../servicios/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

declare var $:any;

@Component({
  selector: 'app-barrenos',
  templateUrl: './barrenos.component.html',
  styleUrls: ['./barrenos.component.css']
})
export class BarrenosComponent implements OnInit {

  private token:string;
  private listaBarrenos:Array<IBarreno>;
  private listaProyectos:Array<IProyecto>;
  private listaEquipos:Array<IEquipo>;
  private tituloAlerta:string;
  private contenidoAlerta:string;
  private textoBotonAlerta:string;
  private idBarrenoEliminar:string;
  private idLote:number;
  private loteNumero:number;
  private nombreLote:string;
  private fechaRegistroLote:string;
  private analisisQuimicoLote:boolean;
  private envioLote:boolean;
  private esAdministrador:boolean;
  public listaArchivosLote:Array<any>;

  constructor(
    private barrenoService:BarrenoService,
    private proyectoService:ProyectosService,
    private focusService:FocusService,
    private spinnerService:SpinnerService,
    private route:ActivatedRoute,
    private _loteService:LotesService,
    private router:Router,
    private location:Location
  ){
    this.token = localStorage.getItem('token');
    this.esAdministrador = localStorage.getItem('role') === 'usuario' ? false : true;
    this.nombreLote = '';
    this.fechaRegistroLote = '';
    this.analisisQuimicoLote = false;
    this.envioLote = false;
    this.listaBarrenos = [];
    this.listaProyectos = [];
    this.listaEquipos = [];
    this.listaArchivosLote = [];
    this.tituloAlerta = '';
    this.contenidoAlerta = '';
    this.textoBotonAlerta = 'Eliminar';
  }

  ngOnInit(){
    this.obtenerParametrosRuta();
    this.spinnerService.cargandoSpinner();
    this.obtenerBarrenosAdministracion();
  }

  obtenerBarrenosAdministracion(){
    this.barrenoService.obtenerBarrenos(this.token)
    .subscribe((data:any) => {
      this.listaEquipos = data.tiposEquipo;
      this.obtenerProyectos();
      this.spinnerService.cerrarSpinner();
    },
    (error:any) => {
      console.log(error);
    });
  }

  agregarBarrenoAdministracion(){
    $('#agregarBarreno').modal('show');
    this.focusService.modalAbierto(true);
  }

  detalleBarrenoAdministracion(id:string){
    this.router.navigate(['lotes', this.idLote, id]);
  }

  eliminarBarrenoAdministracion(idBarreno:string){
    this.barrenoService.eliminarBarreno(this.token, idBarreno)
    .subscribe(() => {
      this.eliminarBarrenoLista(idBarreno);
    },
    (error:any) => {
      console.log(error);
    });
  }

  mostrarCompositos(id:string){
    this.router.navigate(['lotes', this.idLote, 'compositos', id]);
  }

  obtenerNuevoBarreno(barreno:IBarreno){
    this.listaBarrenos.push(barreno);
  }

  obtenerProyectos(){
    this.proyectoService.obtenerProyectos(this.token)
    .subscribe((data:Array<IProyecto>) => {
      this.listaProyectos = data;
    });
  }

  eliminarBarrenoLista(idBarreno:string){
    const indiceBarrenoEncontrado = this.listaBarrenos.findIndex(barreno => barreno.id === idBarreno);
    this.listaBarrenos.splice(indiceBarrenoEncontrado, 1);
    $('#alertaModal').modal('hide');
  }

  mostrarAlertaEliminarBarreno(idBarreno:string){
    $('#alertaModal').modal('show');
    this.tituloAlerta = 'Eliminar Barreno';
    this.contenidoAlerta = '¿Realmente desea eliminar el barreno?';
    this.textoBotonAlerta = 'Eliminar';
    this.idBarrenoEliminar = idBarreno;
  }

  obtenerParametrosRuta(){
    this.route.params
    .subscribe((data:any) => {
      this.obtenerDetalleLote(data.id);
      this.obtenerArchivosLote(data.id);
    });
  }

  regresar(){
    this.location.back();
  }

  obtenerDetalleLote(idLote:number){
    this._loteService.obtenerDetalleLote(this.token, idLote)
    .subscribe((data:any) => {
      this.idLote = data.id;
      this.loteNumero = data.loteNumero;
      this.nombreLote = `Lote ${data.loteNumero}`;
      this.fechaRegistroLote = data.fecha;
      this.analisisQuimicoLote = data.analisisQuimico;
      this.envioLote = data.enviada;
      this.listaBarrenos = data.barrenos;
    });
  }

  public archivosAdjuntos(): void {
    $('#modalSubirArchicoLote').modal('show');
  }

  // Obtenemos la lista de archivos por lote
  public obtenerArchivosLote(idLote:number): void {
    this._loteService.obtenerArchivosLote(this.token, idLote)
    .subscribe((archivos:any) => {
      this.listaArchivosLote = archivos;
    });
  }
}
