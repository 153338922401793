import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from '../../servicios/sesion.service';
import { SpinnerService } from '../../servicios/spinner.service';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  private formularioLogin:FormGroup;

  constructor(private formBuilder:FormBuilder, private sesionService:SesionService, private router:Router, private spinnerService:SpinnerService){
    this.formularioLogin = formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/)])],
      password: ['', Validators.compose([Validators.required])]
    });
  }

  ngOnInit(){}

  public login(){
    this.spinnerService.cargandoSpinner();
    this.sesionService.login(this.formularioLogin.value)
    .subscribe((data:any) => {
      localStorage.setItem('token', data.token);
      localStorage.setItem('role', data.role.toLowerCase());
      if(data.role.toLowerCase() === 'administrador' || data.role.toLowerCase() === 'usuario'){
        this.router.navigate(['lotes']);
        this.spinnerService.cerrarSpinner();
      }
    },
    (error:any) => {
      console.log(error);
      this.spinnerService.cerrarSpinner();
    });
  }

  public redireccionarPagina(){
    this.router.navigate(['olvido-contrasena']);
  }
}
